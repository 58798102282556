import axios from 'axios';

// const API_URL = "http://localhost:8081/api/";
const API_URL = 'https://dev-careers.talently.com/api';

class AuthService {
  login(response: any) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    document.cookie =
      'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  register(username: string, email: string, password: string) {
    return axios.post(API_URL + 'signup', {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default new AuthService();
